/*Estilos generales*/
@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@300&family=Varela+Round&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Varela+Round&display=swap");
// importing core styling file

:root {
  --primary: #006cb5;
  --secondary: #04b6ad;
  --complementary: #292825;
  --white: white;
}

$varela: "Varela Round", sans-serif;
$ubuntu: "Quicksand", sans-serif;
p {
  font-family: $varela !important;
  font-size: 1.4rem !important ;
}
.section {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 6rem);
  border-bottom: 2px rgb(255, 255, 255) solid;
}

/*1:HEADER*/
/*Menu de navegacion*/
.navbar {
  background-color: var(--primary);
  height: 4.5rem;
  .container-fluid {
    background-color: var(--primary);
  }
}
#menu {
  width: 100vw;
  z-index: 1030;
}

/* Estilos para el botón del menú */
#menu .navbar-toggler {
  padding: auto;
  border: 0;
  border-radius: 0;
  outline: 0 !important;
  box-shadow: none !important;
  padding: 1.25rem 0.75rem !important;
  color: transparent !important;
  .navbar-brand {
    background-image: none;
  }
}

/* Estilos para el ícono del menú */
#menu .boton-menu,
.icono-x {
  font-weight: 1000;
  font-size: 1.7rem;
}

/* Estilos para expandir y colapsar el menú */
#menu.expanded .navbar-collapse {
  display: block;
  transition: max-height 0.5s;
}

.active {
  background-color: var(--secondary);
  border-radius: 10px;
  transition: all 0.3s ease;
}
.navbar-nav a {
  transition: all 0.3s ease;
  &:hover {
    background-color: var(--secondary);
    border-radius: 10px;
  }
}
.text-container {
  min-height: 8rem;
}
.text-containerp {
  display: flex;
  align-items: center;
}

.welcome {
  display: flex;
  flex-direction: column;
  background: url("./IMG/portada1.webp") no-repeat center center/cover;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  text-align: center;
}
h1 {
  font-family: $varela;
  text-shadow: 3px 4px 4px rgba(0, 0, 0, 5);
}
.btn-p {
  width: 280px;
  height: 60px;
  font-size: 1.3rem;
  background-color: var(--secondary);
  border-radius: 35px;
  font-family: $varela;
}
.p-welcome {
  text-shadow: 2px 3px 4px rgba(0, 0, 0, 5);
  font-size: 3rem !important;
}

/*2: SOBRE MÍ*/
h2 {
  text-shadow: 1px 1px 2px rgb(131, 131, 131);
}

.pdes {
  font-size: 2rem !important;
}
#about .mt5 {
  margin-top: 4.5rem;
}

/*3: CONOCIMIENTOS*/
.conocimientos {
  background-color: var(--complementary);
}

.li {
  font-family: $ubuntu;
  font-size: 1.8rem;
  margin: 0 auto;
  list-style: none;
}
.conocimientos .row .col-sm-4 {
  border-left: 1px solid white;
  border-right: 1px solid white;
}

/*4: PORTAFOLIO*/
.portafolio .row1 {
  background-color: var(--secondary);
  min-height: 18rem;
  padding-top: 6rem;
}

.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);

  .modal {
    border-radius: 8px;

    .modal-content {
      width: 55%;
      min-height: 30%;
      .conten-title {
        height: 3rem;
        border-radius: 8px 8px 0 0;
        background-color: var(--secondary);
        -webkit-border-radius: 8px 8px 0 0;
        -moz-border-radius: 8px 8px 0 0;
        -ms-border-radius: 8px 8px 0 0;
        -o-border-radius: 8px 8px 0 0;
      }
      .btn:hover {
        background-color: var(--secondary);
      }
      #btnc {
        background-color: red;
      }
      #btnc :hover {
        background-color: red !important;
      }
    }
  }
}
.clipping-container .btn:hover {
  background-color: var(--secondary);
}

/*
.fa-facebook-square {
  color: #1442db;
}

.fa-instagram-square {
  color: #cf39ca;
}


.fa-twitter-square {
  color: #1da1f2;
}

.fa-whatsapp-square {
  color: #2eca43;
}
.fa-telegram {
  color: #26a4e3;
}*/
.triangulo {
  width: 2.5rem;
  height: 2.5rem;
  background-color: var(--secondary);
  left: 50%;
  bottom: 0;
  transform: translateX(-50%) translateY(50%) rotate(45deg);
}

/*5: CONTACTOS*(pendiente para mas adelante)*/
/*
#contacto .row {
  padding-top: 6.5rem;
}

textarea{
  resize: none;
  form-sizing:conten;
}

*/

.footer {
  min-height: 6rem;
  background-color: var(--complementary);

  a {
    line-height: 1.5rem;
    font-family: $varela !important;
    .fa-linkedin {
      color: #0897d4 !important;
    }
  }
}

/*MEDIAS PARA VISTA MOVIL*/

@media (max-width: 576px) {
  .section {
    min-height: calc(100vh + 40rem); //correctamente en inicio
  }
  .modal-container {
    min-height: 100%;
    .modal .modal-content {
      width: 100% !important;
      min-height: 40% !important;
      .btn {
        width: 260px !important;
        height: 70px;
      }
    }
  }
}


